import axios from 'axios';
import { baseURL } from './config';

const API = axios.create({
  baseURL: baseURL,
});

API.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
