import React from "react";
import "../thankyou/thank-you.css";
import { useNavigate } from "react-router-dom";

const AdmissionThankyou = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="common-banner login-banner common-banner-fixed">
        <a href="index.php" className="white-logo">
          <h1>
            <img
              className="pt-16"
              src="https://www.petfelix.com/img/logo-white.png"
              alt="PETFELIX"
            />
          </h1>
        </a>
      </section>
      <div className="log-box login-box contact-bg single-sctn pb-50 mt--300">
        <div className="content bg-none">
          <div className="wrapper-1">
            <div className="wrapper-2 bg-white">
              <p style={{ color: "#000" }}>
                Thanks for submitting the admission form successfully{" "}
              </p>
           
              <button
                class="go-home"
                onClick={() => navigate("/new-booking-form")}
              >
                Booking Form
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionThankyou;
