import React, { useState } from "react";
import "./passcode.css";
import { useNavigate } from "react-router-dom";
import NewBookingForm from "../new-booking-form/new-booking-form";

const Passcode = () => {
  const [passcode, setPasscode] = useState("");
  const [showBooking, setShowBooking] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    var formdata = new FormData();
    formdata.append("password", passcode);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://admin.petfelix.in/api/check-password", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        localStorage.setItem("status", result?.message);
        console.log(result.message);
        if (result?.message == "matched") {
          setShowBooking(true);
          // navigate('/new-booking-form');
        } else {
          alert("Incorrect Passcode");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const passcodeSubmit = () => {
    handleSubmit();
  };

  if (!showBooking) {
    return (
      <>
        <section className="common-banner login-banner common-banner-fixed">
          <a href="index.php" className="white-logo">
            <h1>
              <img
                className="pt-16"
                src="https://www.petfelix.com/img/logo-white.png"
                alt="PETFELIX"
              />
            </h1>
          </a>
        </section>
        <div className="log-box login-box contact-bg single-sctn pb-50 mt--300">
          <div className="content bg-none">
            <div className="wrapper-1">
              <div className="wrapper-2 bg-white p-5 rounded">
                <p style={{ color: "#000", textAlign: "left" }}> Login </p>
                <div className="mt-3 ">
                  <label htmlFor="txtName" className>
                    Passcode
                  </label>
                  <input
                    className="form-control"
                    name="passcode"
                    minLength={10}
                    value={passcode}
                    onChange={(event) => {
                      setPasscode(event.target.value);
                    }}
                    maxLength={10}
                    type="text"
                  />

                  <p />
                </div>
                <p style={{ color: "#000", fontSize: "16px" }}>
                  Kindly contact us in case the passcode isn’t working.
                </p>

                <div className="text-center mt-5">
                  <button
                    type="button"
                    id="add-more-rooms"
                    className="main-btn font-24 mt-0 mb-30 login-form "
                    onClick={passcodeSubmit}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <NewBookingForm />;
  }
};

export default Passcode;
