export const vaccinationData = [
  {
    id: 1,
    name: 'Rabbies',
    key: 'Rabbies',
    isSelect: false,
  },
  {
    id: 2,
    name: 'DHPII',
    key: 'DHPII',
    isSelect: false,
  },
  {
    id: 3,
    name: 'Kennel_Cough',
    key: 'Kennel_Cough',
    isSelect: false,
  },
  {
    id: 4,
    key: 'Corona',
    name: 'Corona',
    isSelect: false,
  },
];
