import React, { useEffect, useState } from "react";
import "./new-booking-form.css";
import { NEW_BOOKING_FORM } from "../../common/constants";
import API from "../../common/api";
import { useNavigate } from "react-router-dom";
import Calendar from "../../assests/calendar.png";
import Clock from "../../assests/clock.png"

export default function NewBookingForm() {
  const navigate = useNavigate();
  const dogsObject = {
    admission_id: null,
    dog_name: "",
    boarding_type: "",
    medicine: null,
    check_in: "",
    check_in_time: "",
    check_out: "",
    check_out_time: "",
    food_change: null,
    breed: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [ownerFirstName, setOwnerFirstName] = useState("");
  const [ownerLastName, setOwnerLastName] = useState("");
  const [data, setData] = useState([]);
  const [dogs, setDogs] = useState([dogsObject]);
  const [show, setShow] = useState(true)
  const [checkedState, setCheckedState] = useState({});
  useEffect(() => {
    // Initialize checked state for each dog item
    const initialState = dogs?.reduce((acc, dog) => {
      return { ...acc, [dog.admission_id]: false };
    }, {});
    setCheckedState(initialState);
  }, [dogs]);
  const handleChange = (admission_id, event) => {
    const { name, value, id } = event.target;
    console.log("ID", id);
    console.log("name", name);
    console.log("value", value);
    setDogs((prevData) => {
      return prevData.map((obj) => {
        if (obj.admission_id == admission_id) {
          return { ...obj, [id]: value };
        }
        return obj;
      });
    });

    setData(event);
  };
  const handleMobile = (e) => {
    e.preventDefault();
    setMobile(e.target.value);
    var myHeaders = new Headers();
    var formData = new FormData();
    formData.append("mobile", e.target.value);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch("https://admin.petfelix.in/api/get-user-details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "response")
        if (result?.status == true) {
          //  console.log('Get User Details', result?.data[0].dogs);
          setOwnerFirstName(result?.data[0]?.name);
          setOwnerLastName(result?.data[0]?.last_name);
          setDogs(result?.data[0]?.dogs);

          if (e.target.value.length == 10) {

            if (result?.data[0]?.dogs?.length > 0) {
              // If dogs are found OR mobile number length is not equal to 10, setShow to true
              setShow(true);

            } else {
              // If dogs are not found AND mobile number length is 10, setShow to false
              setShow(false);
            }
          } else {
            setShow(true)
            setDogs([dogsObject])
            setOwnerFirstName('');
            setOwnerLastName('');
          }

          if (result?.data[0]?.dogs?.length > 0) {
            const data = result?.data[0]?.dogs?.map(
              ({ id, dog_name, breed }) => {
                return {
                  admission_id: id,
                  dog_name: dog_name,
                  breed: breed,
                  boarding_type: "",
                  medicine: null,
                  check_in: "",
                  check_in_time: "",
                  check_out: "",
                  check_out_time: "",
                  food_change: null,
                };
              }
            );

            setDogs(data);
          } else {
            console.log("details not found")

          }
        } else {
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleBookingForm = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const data = {
        first_name: ownerFirstName,
        last_name: ownerLastName,
        mobile: mobile,
        dogs: dogs,
      };

      const response = await API.post(NEW_BOOKING_FORM, data);
      if (response?.data?.status == true) {
        navigate("/thankyou");
        // setDogs(dogsObject);
        setIsLoading(false);
      } else {
        alert("Something went wrong ! Error!");
        setIsLoading(false);
      }
    } catch (error) {
      if(error?.response?.data?.error?.["dogs.0.check_in_time"]?.[1]){
        alert(error?.response?.data?.error?.["dogs.0.check_in_time"]?.[1])

      }
      if (error?.response?.data?.error?.["dogs.0.check_out_time"]?.[0]){
        alert(error?.response?.data?.error?.["dogs.0.check_out_time"]?.[0])
      }else{
        alert("Something went wrong: \n\nPlease check: \n1) All mandatory fields have been filled \n2) Check in date has been filled starting today or a date later from today  \n3) Check in check out time is within the 7:15 am to 8:30 pm window")
      }
    }
  };

  console.log(checkedState, "checeked state")
  const copyMealValues = (prevData) => {
    return prevData.map((obj, index) => {
      if (index !== 0) {
        return {
          ...obj,
          boarding_type: prevData[0].boarding_type,
          check_in: prevData[0].check_in,
          check_in_time: prevData[0].check_in_time,
          check_out: prevData[0].check_out,
          check_out_time: prevData[0].check_out_time
        };
      }
      return obj;
    });
  };

  const handleCopyMealValues = (id, value) => {
    setDogs((prevData) => {
      if (value) {
        return copyMealValues(prevData);
      } else {
        return prevData.map((obj) => {
          if (obj.admission_id === id) {
            // Reset meal values for the specified form
            return {
              ...obj,
              boarding_type: '',
              check_in: '',
              check_in_time: '',
              check_out: '',
              check_out_time: ''

            };
          }
          return obj;
        });
      }
    });
  };

  const handleCheckboxChange = (id, isChecked) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: isChecked
    }));

    setDogs((prevData) => {
      if (!isChecked) {
        return resetMealValues(prevData, id);
      }
      return prevData;
    });
  };
  const resetMealValues = (prevData, id) => {
    return prevData.map((obj) => {
      if (obj.admission_id === id) {
        return {
          ...obj,
          boarding_type: '',
          check_in: '',
          check_in_time: '',
          check_out: '',
          check_out_time: ''
        };
      }
      return obj;
    });
  };
  // States
  const [mobileError, setMobileError] = useState(false);
  const [boardingError, setBoardingError] = useState(false);
  const [medicineError, setMedicineError] = useState(false);
  const [changeFoodError, setChangeFoodError] = useState(false);

  return (
    <div>
      <section className="common-banner login-banner common-banner-fixed">
        <a href="#" className="white-logo">
          <h1>
            <img
              className="pt-16"
              src="https://www.petfelix.com/img/logo-white.png"
              alt="PETFELIX"
            />
          </h1>
        </a>
      </section>
      <div className="log-box login-box contact-bg single-sctn pb-50 mt--300">
        <div className="banner-query border-rad-10 box-sdw-black">
          <form
            onSubmit={(e) => handleBookingForm(e)}
            method="post"
            id="Bookingform"
          >
            <input type="hidden" id="dog_id" name="dog_id" defaultValue />
            <input type="hidden" id="breed" name="breed" defaultValue />
            <div className="checkout-form light-font border-rad-10">
              <div className="form-booking">
                <div className="col-md-8 offset-md-2 mb-30">
                  <h2 className="pb-4 f-24">Booking Form</h2>
                  <div className="d-flex ">
                    <p style={{ color: "red" }}>* </p>
                    <p style={{ color: "#000" }}>
                      &nbsp;These fields are mandatory{" "}
                    </p>
                  </div>
                  <label htmlFor>
                    Mobile No. <span className="text-danger">*</span>
                  </label>
                  <input
                    id="mobile"
                    required="required"
                    name="mobile"
                    onChange={handleMobile}
                    minLength={10}
                    maxLength={10}
                    type="text"
                    value={mobile}
                    className="form-control"
                  />
                  {!mobile && mobileError && (
                    <p className="text-orange">Required</p>
                  )}
                  {
                    !show && (
                      <p className="text-orange sample_search">
                        This mobile number is not registered.Kindly contact Petfelix Team.
                      </p>
                    )
                  }

                </div>
                {
                  show && (
                    <>

                      <div className="col-md-8 offset-md-2 mb-30">
                        <label htmlFor="txtName" className>
                          Owner's First Name
                        </label>
                        <input
                          className="form-control"
                          id="owner_first_name"
                          name="owner_first_name"
                          value={ownerFirstName}
                          placeholder="Owner's First Name"
                          type="text"
                          readOnly
                        />
                      </div>

                      <div className="col-md-8 offset-md-2 mb-30 ">
                        <label htmlFor="txtName" className>
                          Owner's Last Name
                        </label>
                        <input
                          className="form-control"
                          id="owner_last_name"
                          name="owner_last_name"
                          value={ownerLastName}
                          placeholder="Owner's Last Name"
                          type="text"
                          readOnly
                        />
                      </div>




                      {dogs?.map((item, index) => {
                        return (
                          <div key={item?.admission_id}>
                            <div className="col-md-8 offset-md-2 mb-30">
                              {index == 0 ? null : (
                                <h3 style={{ color: "red" }} className="mb-3">
                                  Dog - {index + 1}
                                </h3>
                              )}

                              <label htmlFor>Doggy's Name </label>
                              <input
                                type="text"
                                className="form-control"
                                id="dog_name"
                                name="dog_name"
                                placeholder="Doggy's Name"
                                defaultValue
                                value={item?.dog_name}
                                readOnly
                                required="required"
                              />
                            </div>

                            <div className="col-md-8 offset-md-2 mb-30">
                              <label htmlFor="txtName">
                                Type of Boarding{" "}
                                <span className="text-danger">*</span>
                              </label>
                              {console.log(index)}
                              {
                                index > 0 ?
                                  <div className="row mb-20" style={{ marginTop: 20 }}>

                                    <div className="col-md-12 d-flex align-items-center justify-content-start">
                                      <input
                                        id="copySchedule"
                                        type="checkbox"
                                        style={{ marginRight: 10 }}
                                        onChange={(e) => {
                                          console.log(e.target.checked)
                                          const isChecked = e.target.checked;
                                          handleCheckboxChange(item?.admission_id, isChecked);

                                          handleCopyMealValues(item?.admission_id, e.target.checked)
                                        }}
                                      />
                                      <label htmlFor="copySchedule" style={{ fontStyle: 'italic' }}>Copy boarding details from previous dog </label>

                                    </div>
                                  </div>
                                  :
                                  null
                              }
                              <br />

                              <input
                                type="radio"
                                className="mr-1 dayrCare"
                                defaultValue="Daycare"
                                id="boarding_type"
                                required="required"
                                name={`boarding_type${item.admission_id}`}
                                value={"Daycare"}
                                checked={item.boarding_type === "Daycare"}
                                onChange={(event) => {
                                  setBoardingError(false);
                                  handleChange(item?.admission_id, event);
                                }}
                              />
                              <label className="radio-inline mt-0 radio-class">
                                Daycare
                              </label>

                              <input
                                id="boarding_type"
                                required="required"
                                type="radio"
                                className="mr-1"
                                defaultValue="Overnight"
                                name={`boarding_type${item.admission_id}`}
                                value={"Overnight"}
                                checked={item.boarding_type === "Overnight"}

                                onChange={(event) => {
                                  setBoardingError(false);
                                  handleChange(item.admission_id, event);
                                }}
                              />
                              <label className="radio-inline radio-class">
                                Overnight
                              </label>

                              {!item?.boarding_type && boardingError && (
                                <p className="text-orange">Required</p>
                              )}
                            </div>

                            <div className="col-md-8 offset-md-2 mb-30">
                              <div className="content">
                                <div className="form-group">
                                  <label>
                                    Check in <span className="text-danger">*</span>
                                  </label>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      borderCollapse: "separate",
                                      flexDirection: "row",
                                      alignContent: "center",
                                      width: "100%",
                                      alignItems: "center",
                                    }}
                                    className="input-group date"
                                    id="datepicker1"
                                  >
                                    <input
                                      type="date"
                                      className="form-control left"
                                      required="required"
                                      id="check_in"
                                      placeholder="DD/MM/YYYY"
                                      value={item.check_in}
                                      name="check_in"
                                      onChange={(event) =>
                                        handleChange(item.admission_id, event)
                                      }
                                    />
                                    <span
                                      className="input-group-addon"
                                      style={{
                                        backgroundColor: "#fff !important",
                                        position: "absolute",
                                        right: "6%",
                                        zIndex: 10,
                                        border: 0,
                                      }}
                                    >
                                      <img src={Calendar} className='calendar-icon' />

                                      <span
                                        className="glyphicon glyphicon-calendar"
                                        style={{ padding: ".5rem !important" }}
                                      />
                                    </span>
                                  </div>

                                  {/* {!item.check_in ? (
                                <p style={{ color: 'red' }}>Required*</p>
                              ) : null} */}
                                </div>
                                <div className="form-group">
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      borderCollapse: "separate",
                                      marginTop: "3%",
                                      marginBottom: "3%",
                                      flexDirection: "row",
                                      alignContent: "center",
                                      width: "100%",
                                      alignItems: "center",
                                    }}
                                    className="input-group time"
                                    id="timepicker"
                                  >

                                    <input
                                      type="time"
                                      className="form-control"
                                      required="required"
                                      name="check_in_time"
                                      id="check_in_time"
                                      placeholder="HH:MM AM/PM"
                                      defaultValue="00:00:00"
                                      value={item?.check_in_time}
                                      onChange={(event) =>
                                        handleChange(item.admission_id, event)
                                      }
                                    />
                                    <span
                                      className="input-group-addon"
                                      style={{
                                        backgroundColor: "#fff !important",
                                        position: "absolute",
                                        right: "6%",
                                        zIndex: 10,
                                        border: 0,
                                      }}
                                    >
                                      <img src={Clock} className='clock-icon' />

                                      <span
                                        className="glyphicon glyphicon-time"
                                        style={{
                                          padding: ".2rem !important",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  {/* {!item.check_in_time ? (
                                <p style={{ color: 'red' }}>Required*</p>
                              ) : null} */}
                                </div>
                              </div>
                              <div className="content">
                                <div className="form-group">
                                  <label>
                                    Check Out <span className="text-danger">*</span>
                                  </label>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      borderCollapse: "separate",
                                      flexDirection: "row",
                                      alignContent: "center",
                                      width: "100%",
                                      alignItems: "center",
                                    }}
                                    className="input-group date"
                                    id="datepicker1"
                                  >
                                    <input
                                      type="date"
                                      className="form-control"
                                      required="required"
                                      name="check_out"
                                      id="check_out"
                                      placeholder="DD/MM/YYYY"
                                      value={item?.check_out}
                                      onChange={(event) =>
                                        handleChange(item.admission_id, event)
                                      }
                                    />
                                    <span
                                      className="input-group-addon"
                                      style={{
                                        backgroundColor: "#fff !important",
                                        position: "absolute",
                                        right: "6%",
                                        zIndex: 10,
                                        border: 0,
                                      }}
                                    >
                                      <img src={Calendar} className='calendar-icon' />

                                      <span
                                        className="glyphicon glyphicon-calendar"
                                        style={{ padding: ".5rem !important" }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                {/* {!item.check_out ? (
                              <p style={{ color: 'red' }}>Required*</p>
                            ) : null} */}

                                <div className="form-group">
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      borderCollapse: "separate",
                                      flexDirection: "row",
                                      alignContent: "center",
                                      width: "100%",
                                      alignItems: "center",
                                      marginTop: "3%",
                                    }}
                                    className="input-group time"
                                    id="timepicker1"
                                  >
                                    <input
                                      type="time"
                                      className="form-control"
                                      required="required"
                                      name="check_out_time"
                                      id="check_out_time"
                                      placeholder="HH:MM AM/PM"
                                      defaultValue="00:00:00"
                                      value={item.check_out_time}
                                      onChange={(event) =>
                                        handleChange(item.admission_id, event)
                                      }
                                    />
                                    <span
                                      className="input-group-addon"
                                      style={{
                                        backgroundColor: "#fff !important",
                                        position: "absolute",
                                        right: "6%",
                                        zIndex: 10,
                                        border: 0,
                                      }}
                                    >
                                      <img src={Clock} className='clock-icon' />

                                      <span
                                        className="glyphicon glyphicon-time"
                                        style={{
                                          padding: ".2rem !important",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                {/* {!item.check_out_time ? (
                              <p style={{ color: 'red' }}>Required*</p>
                            ) : null} */}
                              </div>
                            </div>
                            <div className="col-md-8 offset-md-2 mb-30">
                              <label
                                htmlFor="txtName"
                                className="	 mb-2  control-label mb-2  ml--15"
                              >
                                Any medicine during the stay?{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <br />

                              <input
                                id="medicine"
                                type="radio"
                                className="mr-1"
                                name={`medicine${item.admission_id}`}
                                required="required"
                                value={"yes"}
                                onChange={(event) => {
                                  setMedicineError(false);
                                  handleChange(item?.admission_id, event);
                                }}
                              />

                              <label className="radio-inline radio-class">
                                Yes
                              </label>

                              <input
                                id="medicine"
                                required="required"
                                type="radio"
                                className="mr-1"
                                name={`medicine${item.admission_id}`}
                                value={"no"}
                                onChange={(event) => {
                                  setMedicineError(false);
                                  handleChange(item?.admission_id, event);
                                }}
                              />
                              <label className="radio-inline radio-class">No</label>

                              <br />
                              <p className="text-orange">
                                If Yes, kindly send the details of the medicines on
                                whatsapp separately{" "}
                              </p>

                              {!item.medicine && medicineError && (
                                <p className="text-orange">Required</p>
                              )}
                            </div>

                            <div className="col-md-8 offset-md-2 mb-30">
                              <label
                                htmlFor="txtName"
                                className=" mb-2  control-label mb-2 ml--15"
                              >
                                Any change in food details from the last stay here{" "}
                                <span className="text-danger">*</span>
                              </label>{" "}
                              <br />
                              <input
                                type="radio"
                                className="mr-1"
                                required="required"
                                id="food_change"
                                value={"yes"}
                                checked={item?.food_change == 1 ? 1 : null}
                                name={`food_change${item.admission_id}`}
                                onChange={(event) => {
                                  setChangeFoodError(false);
                                  handleChange(item.admission_id, event);
                                }}
                              />
                              <label className="radio-inline mt-0 radio-class">
                                Yes
                              </label>
                              <input
                                type="radio"
                                className="mr-1"
                                required="required"
                                value={"no"}
                                name={`food_change${item.admission_id}`}
                                id="food_change"
                                checked={item?.food_change == 0 ? 0 : null}
                                onChange={(event) => {
                                  setChangeFoodError(false);
                                  handleChange(item.admission_id, event);
                                }}
                              />
                              <label className="radio-inline"> No</label>
                              <br />
                              <p className="text-orange">
                                Please choose NO if this is the first stay here
                              </p>
                              {!item.food_change && changeFoodError && (
                                <p className="text-orange">Required</p>
                              )}
                            </div>
                          </div>
                        );
                      })}

                      <div className="col-md-8 offset-md-2 mb-30">
                        <label
                          htmlFor="txtName"
                          className="  control-label mb-2  ml--15"
                        >
                          Terms &amp; Conditions
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          name
                          style={{ height: "400px" }}
                          className="form-control"
                          id
                          cols={15}
                          placeholder="I represent that my dog is in good health, is current on all required vaccinations, is free of fleas, ticks, and lice, and has not been ill with any known contagious viruses in the last 30 days. I understand that PetFelix reserves the right to refuse admission for any dog in case of any infestation / presence of parasites is observed or if the dog looks to be medically unwell.
1. I understand that if the external parasites have to be treated / removed, the subsequent expenses for the -treatment / application of any medication or grooming - done at PetFelix Doggy boarding and Day care will be borne by me.
2. I understand that any beddings, toys supplied by me may get damaged or destroyed during the stay. I shall not hold PetFelix Doggy boarding and Day care responsible for any such damage.
3. I represent that my dog is sociable and has not harmed or shown threatening behaviours towards any person. I understand that PetFelix Doggy boarding and Day care reserves the right to refuse admission to dogs which show such behaviour.
4. I understand that PetFelix Doggy boarding and Day care's reserves the right to remove a dog from its daycare or boarding facilities at any time in case the dog is unfit to stay in the premises owing to issues of fitment, medical issues, temperament issues, behaviour issues-after intimation to me or my emergency contact. In case myself or the emergency contact is unreachable, I will be charged for the additional period of stay and also be liable to pay a 18% interest per month of the due value.
5. I agree to pay 50% of the fees in advance and 50% at the time of checkout.
6. I agree not to dispute the boarding fees chargeable and to pay the same at the time it is due without any contestation.
7. I understand that my pet will be released from the facility after clearing of all dues. 
8. Closing time for boarding is 8.30 pm. 
9. I agree to pick up my dog prior to closing time. Should I fail to do so I understand I will be charged late fees in the amount of 200 Rs. for 20 mins exceeded.
10. I understand that PetFelix Doggy boarding and Day care reserves the right to refuse check outs after the designated hours. 
11. I understand that should I fail to complete payment within 7 days of the notified due date or do not pick up the dog for any reason within the 7days of the notified date, PetFelix Doggy boarding and Day care will proceed with considering the pet as an abandoned pet in which case, PetFelix boarding and Daycare has the right to take the dog to a third party adoption partner, animal shelter, or attempt to find a new owner for your dog. 
12. I understand that if I fail to pick-up my dog for any reason, I release PetFelix Doggy boarding and Day care, its staff, owners, representatives, and agents from further liability and responsibility for my dog. With my signature below I certify that I have read and understand the agreement and waiver.
13. I understand that if the dog requires urgent/critical/immediate medical care and you are not able to reach me, I allow PetFelix Doggy boarding and Day care’ staff to contact my veterinarian or any other accessible vet clinic as deemed necessary and if required take the dog to a veterinarian or animal hospital. The expenses for this consultation and the treatment there of shall be paid by me.
14. In the event that me, my emergency contact, or any other person i have authorized in writing does not pick up my dog on the agreed upon departure date, i hereby authorize PetFelix Doggy boarding and Day care to continue to provide boarding and any additional services at my expense.
15. In case my dog is being picked up or dropped from my house to the facility or vice -versa by Petfelix, I understand that while they will take utmost precaution and exercise great care, there is still a risk of injury, sickness, damage, the dog running away on the way or even death while the dog is being transported. I release, indemnify and agree to hold Petfelix Doggy Boarding and Daycare harmless from any and all forms damages, claims, loss, liabilities, costs or expenses, cause of actions or suits, whatsoever in law and equity, ( Including without limitation, advocates fees and related legal costs) arising out of or related to pick up or drop services provided by PetFelix Doggy boarding and Day care. 
16. I agree that my dog may be recorded on camera and its images may be used for PetFelix Doggy boarding and Day care’ promotional material. 
17. I understand that although all dogs are fully supervised, that incidents of injuries may occur from playing which include but not limited to bites, scrapes, scratches, and sprains. 
18. I understand that while the team of PetFelix Doggy boarding and Day care will do their very best to ensure that my dog stays safe and comfortable, there are chances, as with any facility housing animals, of an incident taking place. In the remote eventuality of such an incident happening, I release, indemnify and agree to hold PetFelix Doggy boarding and Day care harmless from any and all forms damages, claims, loss, liabilities, costs or expenses, cause of actions or suits, whatsoever in law and equity, ( Including without limitation, advocates fees and related legal costs) arising out of or related to services provided by PetFelix Doggy boarding and Day care. I release PetFelix Doggy boarding and Day care, its staff, owners, representatives, and agents from any and all liability which I or my dog may suffer including but not limited to injury, sickness, the dog running away from the facility, damage or death while the dog  is at the Petfelix premises.
                        "
                          rows={6}
                          defaultValue={""}
                        />
                      </div>
                      <div className="col-md-8 offset-md-2 mb-30">
                        <div className="form-group ml-22" id="err_txtName">
                          <div className=" ">
                            <label className="checkbox pl-i5">
                              <input
                                type="checkbox"
                                name="legal"
                                id="legal"
                                defaultValue="legal"
                                required
                                defaultChecked
                                className="mr-2"
                              />{" "}
                              As the legal owner of the above mentioned dog, i accept
                              all the terms and conditions above{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="text-center pb-50 mt-40">
                        <button
                          name="btnLogin"
                          type="submit"
                          className="main-btn font-24 mt-0 login-form btn"
                        >
                          Submit
                        </button>
                      </div>

                    </>
                  )
                }





              </div>
            </div>
          </form>
        </div>
      </div >
    </div >
  );
}
